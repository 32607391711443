import React, { useEffect, useState } from 'react';
import Template from '../components/Dashboard/Template';
import { ethers } from 'ethers';
import { APP_CONFIG, axiosClient, copyContent } from '../constants';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import BookingsComponent from '../components/Dashboard/BookingsComponent';
import PassDetailsComponent from '../components/Dashboard/PassDetailsComponent';

export default function Dashboard() {
  const token = Cookies.get("USER_TOKEN");
  const [data, setData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [bankData, setBankData] = useState(null);
  const [sponsorData, setSponsorData] = useState(null);
  const [walletAddress, setWalletAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [offers, setOffers] = useState(null);

  const monthsArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  useEffect(() => {
    if (!document.getElementById("select-menu-script")) {
      const script = document.createElement("script");
      script.id = "select-menu-script";
      script.innerHTML = `$( ".input-field__countrycode" ).selectmenu();
      function getCookie(name) {
        const value = '; '+document.cookie;
        const parts = value.split('; '+name+'=');
        if (parts.length === 2) return parts.pop().split(';').shift();
      }
      console.log(getCookie("Country"))
      $( ".input-field__countrycode" ).val(getCookie("Country") ? getCookie("Country") : "India" );
      $( ".input-field__countrycode" ).selectmenu("refresh");
      $( ".input-field__countrycode" ).on('selectmenuchange', function(a,b) {
        document.cookie = "Country="+b?.item?.label?.replace(/\s+/g,' ')?.trim();
        window.location.reload();
      });
      `;
      document.body.appendChild(script);
    }
  }, []);

  useEffect(() => {
    getUserData();
    getSponsorData();
  }, []);

  const getUserData = async () => {
    try {
      const response = await axiosClient.get("/accounts/get-user-details", { headers: { Authorization: "Token " + token } });
      setData(response.data.data);
      getOffers();
      const response1 = await axiosClient.get("/main/get-order", { headers: { Authorization: "Token " + token } });
      setOrderData(response1.data.data);
      const response2 = await axiosClient.get("/accounts/updatebankdetails", { headers: { Authorization: "Token " + token } });
      setBankData(response2.data?.data);
    } catch (error) {
      if (error?.response?.data?.message === "Order not available!") {
        window.location.href = "/pass";
      }
      console.log(error.response.data);
    }
  }

  const getOffers = () => {
    const country = Cookies.get("Country") ? Cookies.get("Country") : "India";
    axiosClient.get("/main/merchant-offer?country=" + country, { headers: { Authorization: "Token " + token } }).then(resp => {
      setOffers(resp.data.data);
    }).catch(err => {
      console.log(err.response);
    })
  }

  const bankAccountWithdraw = async () => {
    if (amount && parseFloat(amount) > 0 && parseFloat(amount) < parseFloat(data?.redeemable_earning)) {
      if (bankData && bankData.account_number) {
        try {
          const mData = {
            amount,
          }
          const res = await axiosClient.post("/accounts/withdraw-earning", mData, { headers: { Authorization: "Token " + token } });
          toast.success("Withdraw To Your Bank Account Successfully.");
        } catch (error) {
          toast.error("Error in withdrawal!!!");
        }
      } else {
        toast.error("Please Update your bank details!!!")
      }
    } else {
      toast.error("You have not enough earnings to withdraw !!!");
    }
  }

  const cryptoWithdraw = async () => {
    if (amount && parseFloat(amount) > 0 && parseFloat(amount) < parseFloat(data?.redeemable_earning)) {
      if (walletAddress) {
        const mData = {
          amount,
          wallet_address: walletAddress
        }
        axiosClient.post("/accounts/withdraw-earning", mData, { headers: { Authorization: "Token " + token } }).then((response1) => {
          toast.success("Withdraw to crypto wallet successfully.")
          window.location.reload();
        }).catch(error1 => {
          console.log(error1);
          toast.error("API ERROR!!!!")
        })
      } else {
        toast.error("Wallet address is required!!!");
      }
    } else {
      toast.error("You have not enough earnings to withdraw !!!");
    }
  }

  const getSponsorData = async () => {
    try {
      const response = await axiosClient.get("/accounts/get-sponsor-tree", { headers: { "Authorization": "Token " + token } });
      setSponsorData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (sponsorData && offers) {
      if (!document.getElementById("myreferrals-scripts")) {
        const script = document.createElement('script');
        script.innerHTML = `
        // Dashboard myreferrals single click toggles child
        $('.myreferrals__single').click(function(){
          if($(this).next().hasClass('myreferrals__single-child')){
            $(this).next().slideToggle();
          }
        })

        // Hot Today Owl Carousels
        if ($(".hot-today-carousel")) {
          $(".hot-today-carousel").owlCarousel({
            loop: false,
            autoplay: true,
            nav: true,
            dots: true,
            slideTransition: "ease-in-out",
            autoplayTimeout: 5000,
            autoplaySpeed: 5000,
            autoplayHoverPause: true,
            margin: 20,
            responsive: {
              0: {
                items: 2,
              },
              568: {
                items: 3,
              },
              768: {
                items: 4,
              },
              992: {
                items: 4,
                margin: 30,
              },
              1200: {
                items: 4,
                margin: 50,
              },
              1500: {
                items: 4,
                margin: 50,
              },
              1800: {
                items: 6,
                margin: 50,
              },
              2200: {
                items: 8,
                margin: 50,
              },
            },
          });
        }
        `;
        script.id = "myreferrals-scripts";
        document.body.appendChild(script);
      }
    }
  }, [sponsorData, offers]);

  return (
    <>
      <button name='India' onClick={() => { getOffers() }} id="country-change-trigger" style={{ display: 'hidden' }}>

      </button>
      <Template active="dashboard" topHeading="Welcome Back!">
        <div className="dashboard-widget calendar-widget">
          <div className="dashboard-widget__left w-100">
            <div className="calendar-page-header mb-5">
              <h3 className="calendar-page-title">My Referrals </h3>
              <p className="calendar-page-desc">You can now view the number of people your referral has referred to</p>
            </div>
            <div className="row mb-5 gx-5">
              <div className="col-lg-6">
                <div className='row'>
                  <div className='col-md-6'>
                    <button onClick={() => { window.location.href = "/sponsor" }} className="mb-2 input-button">
                      <span className="input-button__inner">
                        <span className="input-button__text">View Downlines</span>
                        <span className="input-button__icon-outer">
                          <span className="input-button__icon-inner">
                            <i className="icon icon-double-chevron-dark" />
                          </span>
                        </span>
                      </span>
                    </button>
                  </div>
                  <div className='col-md-6'>
                    {
                      data?.is_blocked &&
                      <button onClick={() => { window.location.href = "/paydues" }} className="mb-2 input-button">
                        <span className="input-button__inner">
                          <span className="input-button__text">Clear All Dues</span>
                          <span className="input-button__icon-outer">
                            <span className="input-button__icon-inner">
                              <i className="icon icon-double-chevron-dark" />
                            </span>
                          </span>
                        </span>
                      </button>
                    }
                  </div>
                </div>
                <div className="myreferrals">
                  {
                    sponsorData && (
                      <>
                        {
                          sponsorData?.childs.length <= 0 &&
                          <h4>You do not have any referrals yet !!!</h4>
                        }
                        {
                          sponsorData?.childs.map((ele1, index1) => (
                            <>
                              <div className="myreferrals__single">
                                <div className="gradient-block-bg">
                                  <div className="gradient-block-bg-inner" />
                                </div>
                                <div className="gradient-block-inner">
                                  <span className="name">{ele1.first_name} {ele1.last_name}<br />
                                    <span style={{ fontSize: "12px", fontWeight: 400 }}>{ele1?.email}</span>
                                  </span>
                                  <span className="number">{ele1?.childs?.length}</span>
                                </div>
                              </div>
                              {
                                ele1?.childs.length > 0 &&
                                <div className="myreferrals__single-child" style={{ display: 'none' }}>
                                  {
                                    ele1?.childs?.slice(0, 1).map((ele, index) => (
                                      <>
                                        <div key={index} className="myreferrals__single">
                                          <div className="gradient-block-bg">
                                            <div className="gradient-block-bg-inner" />
                                          </div>
                                          <div className="gradient-block-inner">
                                            <span className="name">Referral {index + 1}: {ele.first_name} {ele.last_name}</span>
                                            <span className="number">{ele?.childs?.length}</span>
                                          </div>
                                        </div>
                                      </>
                                    ))
                                  }
                                </div>
                              }
                            </>
                          ))
                        }
                      </>)
                  }
                </div>
              </div>
              <div className="col-lg-6">
                <PassDetailsComponent data={data} sponsorData={sponsorData} />
              </div>
            </div>
            <BookingsComponent userData={data} />

            <div className="slider-section mt-5">
              <div className="slider-section__heading">
                <div className="left">
                  <img src="../assets/images/fire-emoji.svg" alt="" />
                  <h2 className="fw600 mb-0 fz18">Exclusive Deals & Offers</h2>
                </div>
                <div className='d-flex align-items-center'>
                  <div className="select-dropdown">
                    <div className="input-text-outer">
                      <div class="input-bg">
                        <div className="input-bg-inner"></div>
                      </div>
                      <div className="input-field">
                        <select className="input-field__countrycode">
                          <option value="UK" selected>UK</option>
                          <option value="USA">USA</option>
                          <option value="Algeria">Algeria</option>
                          <option value="Andorra">Andorra</option>
                          <option value="Angola">Angola</option>
                          <option value="Anguilla">Anguilla</option>
                          <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                          <option value="Argentina">Argentina</option>
                          <option value="Armenia">Armenia</option>
                          <option value="Aruba">Aruba</option>
                          <option value="Australia">Australia</option>
                          <option value="Austria">Austria</option>
                          <option value="Azerbaijan">Azerbaijan</option>
                          <option value="Bahamas">Bahamas</option>
                          <option value="Bahrain">Bahrain</option>
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="Barbados">Barbados</option>
                          <option value="Belarus">Belarus</option>
                          <option value="Belgium">Belgium</option>
                          <option value="Belize">Belize</option>
                          <option value="Benin">Benin</option>
                          <option value="Bermuda">Bermuda</option>
                          <option value="Bhutan">Bhutan</option>
                          <option value="Bolivia">Bolivia</option>
                          <option value="Bosnia Herzegovina">Bosnia Herzegovina</option>
                          <option value="Botswana">Botswana</option>
                          <option value="Brazil">Brazil</option>
                          <option value="Brunei">Brunei</option>
                          <option value="Bulgaria">Bulgaria</option>
                          <option value="Burkina Faso">Burkina Faso</option>
                          <option value="Burundi">Burundi</option>
                          <option value="Cambodia">Cambodia</option>
                          <option value="Cameroon">Cameroon</option>
                          <option value="Canada">Canada</option>
                          <option value="Cape Verde Islands">Cape Verde Islands</option>
                          <option value="Cayman Islands">Cayman Islands</option>
                          <option value="Central African Republic">Central African Republic</option>
                          <option value="Chile">Chile</option>
                          <option value="China">China</option>
                          <option value="Colombia">Colombia</option>
                          <option value="Comoros">Comoros</option>
                          <option value="Congo">Congo</option>
                          <option value="Cook Islands">Cook Islands</option>
                          <option value="Costa Rica">Costa Rica</option>
                          <option value="Croatia">Croatia</option>
                          <option value="Cuba">Cuba</option>
                          <option value="Cyprus North">Cyprus North</option>
                          <option value="Cyprus South">Cyprus South</option>
                          <option value="Czech Republic">Czech Republic</option>
                          <option value="Denmark">Denmark</option>
                          <option value="Djibouti">Djibouti</option>
                          <option value="Dominica">Dominica</option>
                          <option value="Dominican Republic">Dominican Republic</option>
                          <option value="Ecuador">Ecuador</option>
                          <option value="Egypt">Egypt</option>
                          <option value="El Salvador">El Salvador</option>
                          <option value="Equatorial Guinea">Equatorial Guinea</option>
                          <option value="Eritrea">Eritrea</option>
                          <option value="Estonia">Estonia</option>
                          <option value="Ethiopia">Ethiopia</option>
                          <option value="Falkland Islands">Falkland Islands</option>
                          <option value="Faroe Islands">Faroe Islands</option>
                          <option value="Fiji">Fiji</option>
                          <option value="Finland">Finland</option>
                          <option value="France">France</option>
                          <option value="French Guiana">French Guiana</option>
                          <option value="French Polynesia">French Polynesia</option>
                          <option value="Gabon">Gabon</option>
                          <option value="Gambia">Gambia</option>
                          <option value="Georgia">Georgia</option>
                          <option value="Germany">Germany</option>
                          <option value="Ghana">Ghana</option>
                          <option value="Gibraltar">Gibraltar</option>
                          <option value="Greece">Greece</option>
                          <option value="Greenland">Greenland</option>
                          <option value="Grenada">Grenada</option>
                          <option value="Guadeloupe">Guadeloupe</option>
                          <option value="Guam">Guam</option>
                          <option value="Guatemala">Guatemala</option>
                          <option value="Guinea">Guinea</option>
                          <option value="Guinea - Bissau">Guinea - Bissau</option>
                          <option value="Guyana">Guyana</option>
                          <option value="Haiti">Haiti</option>
                          <option value="Honduras">Honduras</option>
                          <option value="Hong Kong">Hong Kong</option>
                          <option value="Hungary">Hungary</option>
                          <option value="Iceland">Iceland</option>
                          <option value="India">India</option>
                          <option value="Indonesia">Indonesia</option>
                          <option value="Iran">Iran</option>
                          <option value="Iraq">Iraq</option>
                          <option value="Ireland">Ireland</option>
                          <option value="Israel">Israel</option>
                          <option value="Italy">Italy</option>
                          <option value="Jamaica">Jamaica</option>
                          <option value="Japan">Japan</option>
                          <option value="Jordan">Jordan</option>
                          <option value="Kazakhstan">Kazakhstan</option>
                          <option value="Kenya">Kenya</option>
                          <option value="Kiribati">Kiribati</option>
                          <option value="Korea North">Korea North</option>
                          <option value="Korea South">Korea South</option>
                          <option value="Kuwait">Kuwait</option>
                          <option value="Kyrgyzstan">Kyrgyzstan</option>
                          <option value="Laos">Laos</option>
                          <option value="Latvia">Latvia</option>
                          <option value="Lebanon">Lebanon</option>
                          <option value="Lesotho">Lesotho</option>
                          <option value="Liberia">Liberia</option>
                          <option value="Libya">Libya</option>
                          <option value="Liechtenstein">Liechtenstein</option>
                          <option value="Lithuania">Lithuania</option>
                          <option value="Luxembourg">Luxembourg</option>
                          <option value="Macao">Macao</option>
                          <option value="Macedonia">Macedonia</option>
                          <option value="Madagascar">Madagascar</option>
                          <option value="Malawi">Malawi</option>
                          <option value="Malaysia">Malaysia</option>
                          <option value="Maldives">Maldives</option>
                          <option value="Mali">Mali</option>
                          <option value="Malta">Malta</option>
                          <option value="Marshall Islands">Marshall Islands</option>
                          <option value="Martinique">Martinique</option>
                          <option value="Mauritania">Mauritania</option>
                          <option value="Mayotte">Mayotte</option>
                          <option value="Mexico">Mexico</option>
                          <option value="Micronesia">Micronesia</option>
                          <option value="Moldova">Moldova</option>
                          <option value="Monaco">Monaco</option>
                          <option value="Mongolia">Mongolia</option>
                          <option value="Montserrat">Montserrat</option>
                          <option value="Morocco">Morocco</option>
                          <option value="Mozambique">Mozambique</option>
                          <option value="Myanmar">Myanmar</option>
                          <option value="Namibia">Namibia</option>
                          <option value="Nauru">Nauru</option>
                          <option value="Nepal">Nepal</option>
                          <option value="Netherlands">Netherlands</option>
                          <option value="New Caledonia">New Caledonia</option>
                          <option value="New Zealand">New Zealand</option>
                          <option value="Nicaragua">Nicaragua</option>
                          <option value="Niger">Niger</option>
                          <option value="Nigeria">Nigeria</option>
                          <option value="Niue">Niue</option>
                          <option value="Norfolk Islands">Norfolk Islands</option>
                          <option value="Northern Marianas">Northern Marianas</option>
                          <option value="Norway">Norway</option>
                          <option value="Oman">Oman</option>
                          <option value="Palau">Palau</option>
                          <option value="Panama">Panama</option>
                          <option value="Papua New Guinea">Papua New Guinea</option>
                          <option value="Paraguay">Paraguay</option>
                          <option value="Peru">Peru</option>
                          <option value="Philippines">Philippines</option>
                          <option value="Poland">Poland</option>
                          <option value="Portugal">Portugal</option>
                          <option value="Puerto Rico">Puerto Rico</option>
                          <option value="Qatar">Qatar</option>
                          <option value="Reunion">Reunion</option>
                          <option value="Romania">Romania</option>
                          <option value="Russia">Russia</option>
                          <option value="Rwanda">Rwanda</option>
                          <option value="San Marino">San Marino</option>
                          <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                          <option value="Saudi Arabia">Saudi Arabia</option>
                          <option value="Senegal">Senegal</option>
                          <option value="Serbia">Serbia</option>
                          <option value="Seychelles">Seychelles</option>
                          <option value="Sierra Leone">Sierra Leone</option>
                          <option value="Singapore">Singapore</option>
                          <option value="Slovak Republic">Slovak Republic</option>
                          <option value="Slovenia">Slovenia</option>
                          <option value="Solomon Islands">Solomon Islands</option>
                          <option value="Somalia">Somalia</option>
                          <option value="South Africa">South Africa</option>
                          <option value="Spain">Spain</option>
                          <option value="Sri Lanka">Sri Lanka</option>
                          <option value="St. Helena">St. Helena</option>
                          <option value="St. Kitts">St. Kitts</option>
                          <option value="St. Lucia">St. Lucia</option>
                          <option value="Sudan">Sudan</option>
                          <option value="Suriname">Suriname</option>
                          <option value="Swaziland">Swaziland</option>
                          <option value="Sweden">Sweden</option>
                          <option value="Switzerland">Switzerland</option>
                          <option value="Syria">Syria</option>
                          <option value="Taiwan">Taiwan</option>
                          <option value="Tajikstan">Tajikstan</option>
                          <option value="Thailand">Thailand</option>
                          <option value="Togo">Togo</option>
                          <option value="Tonga">Tonga</option>
                          <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                          <option value="Tunisia">Tunisia</option>
                          <option value="Turkey">Turkey</option>
                          <option value="Turkmenistan">Turkmenistan</option>
                          <option value="Turkmenistan">Turkmenistan</option>
                          <option value="Turks & Caicos Islands">Turks & Caicos Islands</option>
                          <option value="Tuvalu">Tuvalu</option>
                          <option value="Uganda">Uganda</option>
                          <option value="Ukraine">Ukraine</option>
                          <option value="United Arab Emirates">United Arab Emirates</option>
                          <option value="Uruguay">Uruguay</option>
                          <option value="Uzbekistan">Uzbekistan</option>
                          <option value="Vanuatu">Vanuatu</option>
                          <option value="Vatican City">Vatican City</option>
                          <option value="Venezuela">Venezuela</option>
                          <option value="Vietnam">Vietnam</option>
                          <option value="Virgin Islands - British">Virgin Islands - British</option>
                          <option value="Virgin Islands - US">Virgin Islands - US</option>
                          <option value="Wallis & Futuna">Wallis & Futuna</option>
                          <option value="Yemen">Yemen</option>
                          <option value="Yemen">Yemen</option>
                          <option value="Zambia">Zambia</option>
                          <option value="Zimbabwe">Zimbabwe</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* <a href="#" className="ms-2 fw400 fz12 viewAll"> View All </a> */}
                </div>
              </div>
              {
                offers && (
                  <div className="owl-carousel owl-theme hot-today-carousel">
                    {offers?.map(ele => (
                      <div className="item cursor-pointer" onClick={() => { window.location.href = "/offer/" + ele.uuid }}>
                        <div className="gradient-block-outer hot-today-card hot-today-card--01">
                          <div className="gradient-block-bg">
                            <div className="gradient-block-bg-inner" />
                          </div>
                          <div className="w-100 gradient-block-inner position-relative hot-today-card__inner">
                            <img style={{ objectFit: 'cover' }} src={APP_CONFIG.API_URL.replace("/api", "") + ele.image} alt="..." />
                            {
                              ele.offer_type == "event" ?
                                <div className="bg-white fw600 date-sticker">
                                  <div className="fz16 num">{new Date(ele.offer_date).getDate()}</div>
                                  <div className="text">{monthsArray[new Date(ele.offer_date).getMonth()]}</div>
                                </div> : null
                            }
                            <div className="text-white card-text">
                              <p className="fw400 fz12 mb-0">Location: {ele.country}</p>
                              <span className="fw400 fz12">{ele.title}</span>
                              <div className="fw600 fz16">
                                {ele.offer}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )
              }
              {
                (!offers || offers.length <= 0) && (
                  <div className='mb-5'>
                    <h2>No Deals & Offers are available !!!</h2>
                  </div>
                )
              }
            </div>

          </div>
        </div>
      </Template>

      {/* Payment Modal */}
      <div className="modal fade modal--style1" id="paymentMethodModal" tabIndex={-1} aria-labelledby="paymentMethodModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-gradient"><div className="modal-gradient-inner" /></div>
            <div className="modal-header modal-header--noborder">
              <h2 className="modal-title text-center" id="paymentMethodModalLabel">
                Withdraw Your Earning
              </h2>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body text-center mb-3">

              {
                data?.is_blocked ?
                  <>
                    <h2>You account is blocked ! please pay due amount.</h2>
                    <div className="input-button-wrapper mt-4 mx-auto" style={{ maxWidth: "300px" }}>
                      <button onClick={() => { window.location.href = "/paydues" }} className="input-button">
                        <span className="input-button__inner">
                          <span className="input-button__text">Pay Now</span>
                          <span className="input-button__icon-outer">
                            <span className="input-button__icon-inner">
                              <i className="icon icon-double-chevron-dark" />
                            </span>
                          </span>
                        </span>
                      </button>
                    </div>
                  </>
                  :
                  <>
                    <div className="row mt-4 px-4 mb-4">
                      <>
                        <div className='col-lg-6 pe-lg-5 mb-4 mb-lg-0 br-lg-1 bb-lg-r-1 pb-4'>
                          <h4 className='text-center mb-4'>Withdraw in Crypto</h4>
                          <div className="input-text-outer mb-4">
                            <div className="input-bg">
                              <div className="input-bg-inner" />
                            </div>
                            <div className="input-field">
                              <span className="input-field__prefix"><i className="icon icon-wallet" /></span>
                              <input type="text" placeholder="Enter Amount" value={amount} onChange={(e) => setAmount(e.target.value)} name='walletAddress' />
                            </div>
                          </div>
                          <div className="input-text-outer mb-4">
                            <div className="input-bg">
                              <div className="input-bg-inner" />
                            </div>
                            <div className="input-field">
                              <span className="input-field__prefix"><i className="icon icon-wallet" /></span>
                              <input type="text" placeholder="Enter Your Wallet Address" value={walletAddress} onChange={(e) => setWalletAddress(e.target.value)} name='walletAddress' />
                            </div>
                          </div>
                          <div className="input-button-wrapper">
                            <button className="input-button" onClick={cryptoWithdraw}>
                              <span className="input-button__inner">
                                <span className="input-button__text">Submit</span>
                                <span className="input-button__icon-outer">
                                  <span className="input-button__icon-inner">
                                    <i className="icon icon-double-chevron-dark" />
                                  </span>
                                </span>
                              </span>
                            </button>
                          </div>
                          <p className='text-center mt-4 mb-0'><b>Note :</b> 5% Extra you need to pay.</p>
                        </div>
                        <div className='col-lg-6 ps-lg-5'>
                          <h4 className='text-center mb-4'>Withdraw in Bank Account</h4>
                          <div>
                            <div className="input-text-outer mb-4">
                              <div className="input-bg">
                                <div className="input-bg-inner" />
                              </div>
                              <div className="input-field">
                                <span className="input-field__prefix"><i className="icon icon-wallet" /></span>
                                <input type="text" placeholder="Enter Amount" value={amount} onChange={(e) => setAmount(e.target.value)} name='walletAddress' required />
                              </div>
                            </div>
                            <div className="input-text-outer mb-4">
                              <div className="input-bg">
                                <div className="input-bg-inner" />
                              </div>
                              <div className="input-field">
                                <span className="input-field__prefix"><i className="icon icon-bank" /></span>
                                <input type="text" value={bankData?.account_number} placeholder='Account Number' disabled={true} required />
                              </div>
                            </div>
                          </div>
                          <div className="input-button-wrapper">
                            <button className="input-button" onClick={bankAccountWithdraw}>
                              <span className="input-button__inner">
                                <span className="input-button__text">Submit</span>
                                <span className="input-button__icon-outer">
                                  <span className="input-button__icon-inner">
                                    <i className="icon icon-double-chevron-dark" />
                                  </span>
                                </span>
                              </span>
                            </button>
                          </div>
                          <p className='text-center mt-4 mb-0'><a className='text-red underlined me-1' href="/profile">Click Here</a> to Add Bank Account</p>
                        </div>
                      </>
                      {/* } */}
                    </div>
                  </>
              }
            </div>
          </div>
        </div>
      </div>

      <MyModel1 />
    </>
  )
}

const MyModel1 = () => (
  <div className="modal fade modal--style1" id="underMaturity" tabIndex={-1} aria-labelledby="underMaturityLabel" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-gradient"><div className="modal-gradient-inner" /></div>
        <div className="modal-header modal-header--noborder">
          <h2 className="modal-title text-center" id="underMaturityLabel">
            Under Maturity
          </h2>
          <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body text-center mb-3 py-5">
          <div className="row paymethod--row">
            <h2>Redeemable after 20 days of cooling period</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
)