import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { axiosClient } from '../../constants';
import AdminTemplate from './AdminTemplate';

export default function AdminDashboard({ active }) {
  const [data, setData] = useState(null);
  const headers = { Authorization: "Token " + Cookies.get("ADMIN_TOKEN") }

  useEffect(() => {
    if (data) {
      let script = document.createElement("script");
      script.id = "data-table-script";
      script.innerHTML = `new DataTable('#exampleDataTable');`;
      document.body.appendChild(script);
    }
  }, [data]);

  const getData = () => {
    axiosClient.get("/accounts/get-all-user", { headers }).then(resp => {
      setData(resp.data.data);
    }).catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    if (Cookies.get("ADMIN_TOKEN") && Cookies.get("ADMIN_DATA")) { } else {
      window.location.href = "/admin";
    }
    getData();
  }, []);

  return (
    <AdminTemplate active="dashboard">
      <div className='container text-white'>
        <h2 className='page-title'>All User</h2>
        <div className="gradient-block-outer">
          <div className="gradient-block-bg">
            <div className="gradient-block-bg-inner"></div>
          </div>
          <div className="gradient-block-inner doc-block2">
            <div className="table-responsive">
              <table id="exampleDataTable" class="table table-striped">
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Downlines</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data?.map(elem => (
                      <tr>
                        <td>{elem.first_name} {elem.last_name}</td>
                        <td>{elem.email}</td>
                        <td>{elem.phone}</td>
                        <td>{elem.direct_sale}</td>
                        <td>
                          <div className='d-flex flex-wrap gap-2'>
                            <a href={`mailto:${elem.email}`}><i className='icon icon-email'></i></a>
                            <a href={`/admin/user/${elem.email}`}>
                              <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="style=bulk"> <g id="profile"> <path id="vector (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M6.75 6.5C6.75 3.6005 9.1005 1.25 12 1.25C14.8995 1.25 17.25 3.6005 17.25 6.5C17.25 9.3995 14.8995 11.75 12 11.75C9.1005 11.75 6.75 9.3995 6.75 6.5Z" fill="#ffffff"></path> <path id="rec (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M4.25 18.5714C4.25 15.6325 6.63249 13.25 9.57143 13.25H14.4286C17.3675 13.25 19.75 15.6325 19.75 18.5714C19.75 20.8792 17.8792 22.75 15.5714 22.75H8.42857C6.12081 22.75 4.25 20.8792 4.25 18.5714Z" fill="#ffffff"></path> </g> </g> </g></svg>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </AdminTemplate>
    // <>
    //   <div className="bg-setup">
    //     <div className="blobs" />
    //   </div>

    //   {/* SideBar */}
    //   <div className="gradient-block-outer sidebar">
    //     <div className="gradient-block-bg gradient-block-bg--verticalCard">
    //       <div className="gradient-block-bg-inner" />
    //     </div>
    //     <div className="gradient-block-inner sidebar__inner">
    //       <div className="logo-small">
    //         <img src="/assets/images/logo-small.svg" alt="Logo small image" />
    //       </div>
    //       <ul className="list-unstyled">
    //         <li>
    //           <a href="/admin/dashboard" className={`app-links active`}
    //             data-bs-toggle="tooltip"
    //             data-bs-placement="right"
    //             data-bs-title="Dashboard">
    //             <svg
    //               width={23}
    //               height={24}
    //               viewBox="0 0 23 24"
    //               fill="none"
    //               xmlns="http://www.w3.org/2000/svg"
    //             >
    //               <path
    //                 d="M21.1034 7.22008L13.6552 0.915623C12.9724 0.325982 12.0885 0 11.1725 0C10.2564 0 9.37249 0.325982 8.68971 0.915623L1.24149 7.22008C0.84719 7.56056 0.532542 7.97824 0.318494 8.44531C0.104447 8.91237 -0.00408729 9.41811 0.000117675 9.92884V20.4043C0.000117675 21.3579 0.392478 22.2725 1.09088 22.9468C1.78929 23.6212 2.73653 24 3.72423 24H18.6207C19.6084 24 20.5556 23.6212 21.254 22.9468C21.9524 22.2725 22.3448 21.3579 22.3448 20.4043V9.91685C22.3472 9.40814 22.2378 8.90471 22.0239 8.43984C21.8099 7.97496 21.4961 7.55922 21.1034 7.22008ZM13.6552 21.6029H8.68971V15.61C8.68971 15.2922 8.8205 14.9873 9.0533 14.7625C9.2861 14.5378 9.60185 14.4115 9.93108 14.4115H12.4138C12.7431 14.4115 13.0588 14.5378 13.2916 14.7625C13.5244 14.9873 13.6552 15.2922 13.6552 15.61V21.6029ZM19.862 20.4043C19.862 20.7222 19.7313 21.027 19.4985 21.2518C19.2657 21.4766 18.9499 21.6029 18.6207 21.6029H16.1379V15.61C16.1379 14.6564 15.7456 13.7418 15.0472 13.0675C14.3488 12.3932 13.4015 12.0143 12.4138 12.0143H9.93108C8.94339 12.0143 7.99614 12.3932 7.29774 13.0675C6.59933 13.7418 6.20697 14.6564 6.20697 15.61V21.6029H3.72423C3.395 21.6029 3.07925 21.4766 2.84645 21.2518C2.61365 21.027 2.48286 20.7222 2.48286 20.4043V9.91685C2.48308 9.74667 2.52084 9.57849 2.59361 9.4235C2.66638 9.2685 2.77251 9.13025 2.90493 9.01793L10.3531 2.72546C10.5797 2.5333 10.8709 2.42733 11.1725 2.42733C11.474 2.42733 11.7652 2.5333 11.9918 2.72546L19.44 9.01793C19.5724 9.13025 19.6785 9.2685 19.7513 9.4235C19.8241 9.57849 19.8618 9.74667 19.862 9.91685V20.4043Z"
    //                 className="fill-icon"
    //               />
    //             </svg>
    //           </a>
    //         </li>
    //         <li>
    //           <a href="/admin/verify" className={`app-links`} data-bs-toggle="tooltip"
    //             data-bs-placement="right"
    //             data-bs-title="Levels">
    //             <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    //               <path d="M10.9375 1.75H3.9375C2.7125 1.75 1.75 2.7125 1.75 3.9375V10.9375C1.75 12.1625 2.7125 13.125 3.9375 13.125H10.9375C12.1625 13.125 13.125 12.1625 13.125 10.9375V3.9375C13.125 2.7125 12.1625 1.75 10.9375 1.75ZM11.375 10.9375C11.375 11.2 11.2 11.375 10.9375 11.375H3.9375C3.675 11.375 3.5 11.2 3.5 10.9375V3.9375C3.5 3.675 3.675 3.5 3.9375 3.5H10.9375C11.2 3.5 11.375 3.675 11.375 3.9375V10.9375Z" fill="white" />
    //               <path d="M8.75 5.25H6.125C5.6 5.25 5.25 5.6 5.25 6.125V8.75C5.25 9.275 5.6 9.625 6.125 9.625H8.75C9.275 9.625 9.625 9.275 9.625 8.75V6.125C9.625 5.6 9.275 5.25 8.75 5.25ZM7.875 7.875H7V7H7.875V7.875ZM24.0625 1.75H17.0625C15.8375 1.75 14.875 2.7125 14.875 3.9375V10.9375C14.875 12.1625 15.8375 13.125 17.0625 13.125H24.0625C25.2875 13.125 26.25 12.1625 26.25 10.9375V3.9375C26.25 2.7125 25.2875 1.75 24.0625 1.75ZM24.5 10.9375C24.5 11.2 24.325 11.375 24.0625 11.375H17.0625C16.8 11.375 16.625 11.2 16.625 10.9375V3.9375C16.625 3.675 16.8 3.5 17.0625 3.5H24.0625C24.325 3.5 24.5 3.675 24.5 3.9375V10.9375Z" fill="white" />
    //               <path d="M21.875 5.25H19.25C18.725 5.25 18.375 5.6 18.375 6.125V8.75C18.375 9.275 18.725 9.625 19.25 9.625H21.875C22.4 9.625 22.75 9.275 22.75 8.75V6.125C22.75 5.6 22.4 5.25 21.875 5.25ZM21 7.875H20.125V7H21V7.875ZM1.75 24.0625C1.75 25.2875 2.7125 26.25 3.9375 26.25H10.9375C12.1625 26.25 13.125 25.2875 13.125 24.0625V17.0625C13.125 15.8375 12.1625 14.875 10.9375 14.875H3.9375C2.7125 14.875 1.75 15.8375 1.75 17.0625V24.0625ZM3.5 17.0625C3.5 16.8 3.675 16.625 3.9375 16.625H10.9375C11.2 16.625 11.375 16.8 11.375 17.0625V24.0625C11.375 24.325 11.2 24.5 10.9375 24.5H3.9375C3.675 24.5 3.5 24.325 3.5 24.0625V17.0625Z" fill="white" />
    //               <path d="M6.125 22.75H8.75C9.275 22.75 9.625 22.4 9.625 21.875V19.25C9.625 18.725 9.275 18.375 8.75 18.375H6.125C5.6 18.375 5.25 18.725 5.25 19.25V21.875C5.25 22.4 5.6 22.75 6.125 22.75ZM7 20.125H7.875V21H7V20.125ZM24.0625 14.875H17.0625C15.8375 14.875 14.875 15.8375 14.875 17.0625V24.0625C14.875 25.2875 15.8375 26.25 17.0625 26.25H24.0625C25.2875 26.25 26.25 25.2875 26.25 24.0625V17.0625C26.25 15.8375 25.2875 14.875 24.0625 14.875ZM24.5 24.0625C24.5 24.325 24.325 24.5 24.0625 24.5H17.0625C16.8 24.5 16.625 24.325 16.625 24.0625V17.0625C16.625 16.8 16.8 16.625 17.0625 16.625H24.0625C24.325 16.625 24.5 16.8 24.5 17.0625V24.0625Z" fill="white" />
    //               <path d="M21.875 18.375H19.25C18.725 18.375 18.375 18.725 18.375 19.25V21.875C18.375 22.4 18.725 22.75 19.25 22.75H21.875C22.4 22.75 22.75 22.4 22.75 21.875V19.25C22.75 18.725 22.4 18.375 21.875 18.375ZM21 21H20.125V20.125H21V21Z" fill="white" />
    //             </svg>
    //           </a>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>

    //   <div className={`dashboard dashboard1 ps-0 py-lg-5 `}>
    //     <div className="dashboard-top-btn-row pt-lg-5 mt-lg-3">
    //       {/* Top actions btn row */}
    //       <div className="top-btns top-btns--withSidebar">
    //         <div className="gradient-block-bg">
    //           <div className="gradient-block-bg-inner"></div>
    //         </div>
    //         <div className='gradient-block-inner'>
    //           <div className="top-btns__left">
    //             <div className="logo-small me-2">
    //               <div className="logo-small__bg1">
    //                 <div className="logo-small__bg2">
    //                   <img src="/assets/images/logo-small.svg" alt="Logo small image" />
    //                 </div>
    //               </div>
    //             </div>

    //             <h2 className="d-none d-lg-block text-white mb-0 fz20 fw600">Dashboard</h2>
    //           </div>

    //           <ul className="nav-links">
    //             <li>
    //               <a href="/admin/dashboard" className={`app-links active`} data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Dashboard">
    //                 <svg width={23} height={24} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                   <path d="M21.1034 7.22008L13.6552 0.915623C12.9724 0.325982 12.0885 0 11.1725 0C10.2564 0 9.37249 0.325982 8.68971 0.915623L1.24149 7.22008C0.84719 7.56056 0.532542 7.97824 0.318494 8.44531C0.104447 8.91237 -0.00408729 9.41811 0.000117675 9.92884V20.4043C0.000117675 21.3579 0.392478 22.2725 1.09088 22.9468C1.78929 23.6212 2.73653 24 3.72423 24H18.6207C19.6084 24 20.5556 23.6212 21.254 22.9468C21.9524 22.2725 22.3448 21.3579 22.3448 20.4043V9.91685C22.3472 9.40814 22.2378 8.90471 22.0239 8.43984C21.8099 7.97496 21.4961 7.55922 21.1034 7.22008ZM13.6552 21.6029H8.68971V15.61C8.68971 15.2922 8.8205 14.9873 9.0533 14.7625C9.2861 14.5378 9.60185 14.4115 9.93108 14.4115H12.4138C12.7431 14.4115 13.0588 14.5378 13.2916 14.7625C13.5244 14.9873 13.6552 15.2922 13.6552 15.61V21.6029ZM19.862 20.4043C19.862 20.7222 19.7313 21.027 19.4985 21.2518C19.2657 21.4766 18.9499 21.6029 18.6207 21.6029H16.1379V15.61C16.1379 14.6564 15.7456 13.7418 15.0472 13.0675C14.3488 12.3932 13.4015 12.0143 12.4138 12.0143H9.93108C8.94339 12.0143 7.99614 12.3932 7.29774 13.0675C6.59933 13.7418 6.20697 14.6564 6.20697 15.61V21.6029H3.72423C3.395 21.6029 3.07925 21.4766 2.84645 21.2518C2.61365 21.027 2.48286 20.7222 2.48286 20.4043V9.91685C2.48308 9.74667 2.52084 9.57849 2.59361 9.4235C2.66638 9.2685 2.77251 9.13025 2.90493 9.01793L10.3531 2.72546C10.5797 2.5333 10.8709 2.42733 11.1725 2.42733C11.474 2.42733 11.7652 2.5333 11.9918 2.72546L19.44 9.01793C19.5724 9.13025 19.6785 9.2685 19.7513 9.4235C19.8241 9.57849 19.8618 9.74667 19.862 9.91685V20.4043Z" className="fill-icon" />
    //                 </svg>
    //               </a>
    //             </li>
    //             <li>
    //               <a href="/admin/verify" className={`app-links`} data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Dashboard">
    //                 <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                   <path d="M10.9375 1.75H3.9375C2.7125 1.75 1.75 2.7125 1.75 3.9375V10.9375C1.75 12.1625 2.7125 13.125 3.9375 13.125H10.9375C12.1625 13.125 13.125 12.1625 13.125 10.9375V3.9375C13.125 2.7125 12.1625 1.75 10.9375 1.75ZM11.375 10.9375C11.375 11.2 11.2 11.375 10.9375 11.375H3.9375C3.675 11.375 3.5 11.2 3.5 10.9375V3.9375C3.5 3.675 3.675 3.5 3.9375 3.5H10.9375C11.2 3.5 11.375 3.675 11.375 3.9375V10.9375Z" fill="white" />
    //                   <path d="M8.75 5.25H6.125C5.6 5.25 5.25 5.6 5.25 6.125V8.75C5.25 9.275 5.6 9.625 6.125 9.625H8.75C9.275 9.625 9.625 9.275 9.625 8.75V6.125C9.625 5.6 9.275 5.25 8.75 5.25ZM7.875 7.875H7V7H7.875V7.875ZM24.0625 1.75H17.0625C15.8375 1.75 14.875 2.7125 14.875 3.9375V10.9375C14.875 12.1625 15.8375 13.125 17.0625 13.125H24.0625C25.2875 13.125 26.25 12.1625 26.25 10.9375V3.9375C26.25 2.7125 25.2875 1.75 24.0625 1.75ZM24.5 10.9375C24.5 11.2 24.325 11.375 24.0625 11.375H17.0625C16.8 11.375 16.625 11.2 16.625 10.9375V3.9375C16.625 3.675 16.8 3.5 17.0625 3.5H24.0625C24.325 3.5 24.5 3.675 24.5 3.9375V10.9375Z" fill="white" />
    //                   <path d="M21.875 5.25H19.25C18.725 5.25 18.375 5.6 18.375 6.125V8.75C18.375 9.275 18.725 9.625 19.25 9.625H21.875C22.4 9.625 22.75 9.275 22.75 8.75V6.125C22.75 5.6 22.4 5.25 21.875 5.25ZM21 7.875H20.125V7H21V7.875ZM1.75 24.0625C1.75 25.2875 2.7125 26.25 3.9375 26.25H10.9375C12.1625 26.25 13.125 25.2875 13.125 24.0625V17.0625C13.125 15.8375 12.1625 14.875 10.9375 14.875H3.9375C2.7125 14.875 1.75 15.8375 1.75 17.0625V24.0625ZM3.5 17.0625C3.5 16.8 3.675 16.625 3.9375 16.625H10.9375C11.2 16.625 11.375 16.8 11.375 17.0625V24.0625C11.375 24.325 11.2 24.5 10.9375 24.5H3.9375C3.675 24.5 3.5 24.325 3.5 24.0625V17.0625Z" fill="white" />
    //                   <path d="M6.125 22.75H8.75C9.275 22.75 9.625 22.4 9.625 21.875V19.25C9.625 18.725 9.275 18.375 8.75 18.375H6.125C5.6 18.375 5.25 18.725 5.25 19.25V21.875C5.25 22.4 5.6 22.75 6.125 22.75ZM7 20.125H7.875V21H7V20.125ZM24.0625 14.875H17.0625C15.8375 14.875 14.875 15.8375 14.875 17.0625V24.0625C14.875 25.2875 15.8375 26.25 17.0625 26.25H24.0625C25.2875 26.25 26.25 25.2875 26.25 24.0625V17.0625C26.25 15.8375 25.2875 14.875 24.0625 14.875ZM24.5 24.0625C24.5 24.325 24.325 24.5 24.0625 24.5H17.0625C16.8 24.5 16.625 24.325 16.625 24.0625V17.0625C16.625 16.8 16.8 16.625 17.0625 16.625H24.0625C24.325 16.625 24.5 16.8 24.5 17.0625V24.0625Z" fill="white" />
    //                   <path d="M21.875 18.375H19.25C18.725 18.375 18.375 18.725 18.375 19.25V21.875C18.375 22.4 18.725 22.75 19.25 22.75H21.875C22.4 22.75 22.75 22.4 22.75 21.875V19.25C22.75 18.725 22.4 18.375 21.875 18.375ZM21 21H20.125V20.125H21V21Z" fill="white" />
    //                 </svg>
    //               </a>
    //             </li>
    //           </ul>

    //           <div className="top-btns__right">
    //             <div className="user cursor-pointer" onClick={() => window.location.href = "/profile"}>
    //               <div className="position-relative rounded-circle user__img">
    //                 <div className="rounded-circle bg-white w-100 h-100 fz20 fw600 initials">
    //                   {/* {userData?.first_name?.slice(0, 1)?.toUpperCase()} */}
    //                 </div>
    //                 <div className="rounded-circle dot" />
    //               </div>
    //               <div className="user__info">
    //                 <h3 className="fw600 fz20 mb-0 text-white"></h3>
    //                 {/* <span className="fz14 fw300">{userData?.account_type == "user" ? "User" : "Independent Distributor"}</span> */}
    //               </div>
    //             </div>
    //             <a href="#" className="notify">
    //               <div className="rounded-circle dot" />
    //               <img src="/assets/images/icons/bell-icon.svg" alt="" />
    //             </a>
    //             <a onClick={(e) => { e.preventDefault(); logout(); }} className="logout cursor-pointer">
    //               <img src="/assets/images/icons/logout-icon.svg" alt="" />
    //             </a>
    //           </div>
    //         </div>
    //       </div>

    //       <div className='container text-white'>
    //         <h2 className='page-title'>All User</h2>
    //         <div className="gradient-block-outer">
    //           <div className="gradient-block-bg">
    //             <div className="gradient-block-bg-inner"></div>
    //           </div>
    //           <div className="gradient-block-inner doc-block2">
    //             <div className="table-responsive">
    //               <table id="exampleDataTable" class="table table-striped">
    //                 <thead>
    //                   <tr>
    //                     <th>Full Name</th>
    //                     <th>Email</th>
    //                     <th>Phone</th>
    //                     <th>Downlines</th>
    //                     <th>Action</th>
    //                   </tr>
    //                 </thead>
    //                 <tbody>
    //                   {
    //                     data?.map(elem => (
    //                       <tr>
    //                         <td>{elem.first_name} {elem.last_name}</td>
    //                         <td>{elem.email}</td>
    //                         <td>{elem.phone}</td>
    //                         <td>{elem.direct_sale}</td>
    //                         <td><a href={`mailto:${elem.email}`}><i className='icon icon-email'></i></a></td>
    //                       </tr>
    //                     ))
    //                   }
    //                 </tbody>
    //               </table>
    //             </div>
    //           </div>
    //         </div>


    //       </div>

    //     </div>

    //   </div>


    // </>
  )
}