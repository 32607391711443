import React, { useEffect, useState } from 'react'
import { axiosClient } from '../constants';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

export default function BookSlot() {
  const [currentDate, setCurrentDate] = useState("");
  const [active, setActive] = useState("Dubai");
  const [sponsorData, setSponsorData] = useState({});
  let monthsArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const getSponsorData = async () => {
    const token = Cookies.get("USER_TOKEN");
    try {
      const response = await axiosClient.get("/accounts/get-sponsor-tree", { headers: { "Authorization": "Token " + token } });
      setSponsorData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getTodayDate = () => {
    let mm = new Date().getMonth() + 1;
    mm = mm < 10 ? "0" + mm : mm;
    let dd = new Date().getDate();
    dd = dd < 10 ? "0" + dd : dd;
    let yyyy = new Date().getFullYear();
    return mm + "/" + dd + "/" + yyyy;
  }

  useEffect(() => {
    document.getElementById("my-date-input").value = getTodayDate();
    const script = document.createElement("script");
    script.innerHTML = `
    $(function() {
      $( "#my_date_picker" ).datepicker({
        firstDay: 1,
        showOtherMonths: true,
        minDate: 0,
        onSelect: function(dateText, instance) {
          document.getElementById("my-date-input").value = dateText;
        },
      });
    });
    `;
    script.async = true;
    document.body.appendChild(script);
    getSponsorData();
  }, [])

  const onClickBookSlot = async (e) => {
    e.preventDefault();
    const token = Cookies.get("USER_TOKEN");
    let slot_date = document.getElementById("my-date-input").value.split("/");
    try {
      let slot_date_format = slot_date[2] + "-" + slot_date[0] + "-" + slot_date[1];
      const response = await axiosClient.post("/main/book-slot", { slot_date: slot_date_format, club_name: active }, { headers: { Authorization: "Token " + token } });
      toast.success("Slot Booked Successfully.");
      window.location.href = "/dashboard";
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  }

  const onClickBuy = () => {
    const temp = document.getElementById("my-date-input").value
    setCurrentDate(temp);
  }

  return (
    <div>
      <div className="bg-setup">
        <div className="blobs">
        </div>
      </div>
      <div className="main-page">
        <div className="container">
          {/* Top actions btn row */}
          <div className="top-btns">
            <div className="top-btns__left">
              <a href="dashboard">
                <img src="../assets/images/icons/icon-chevron-left.svg" alt="" />
              </a>
            </div>
            <div className="top-btns__right">
              <a href="#" className="notify">
                <div className="rounded-circle dot" />
                <img src="../assets/images/icons/bell-icon.svg" alt="" />
              </a>
              <a href="#" className="logout">
                <img src="../assets/images/icons/logout-icon.svg" alt="" />
              </a>
            </div>
          </div>
          {/* ===================== */}
          <div className="sponsor pass">
            <div className="sponsor__left position-relative sponsor__left1">
              <div className="cards123 fixed cards123--signup">
                <div className="cards123__single">
                  <div className="cards123__bg" />
                  <div className="cards123__img">
                    <div className="cards123__bg-inner" />
                    <img src="../assets/images/signup/img5.png" alt="" />
                  </div>
                </div>
                <div className="cards123__single">
                  <div className="cards123__bg" />
                  <div className="cards123__img">
                    <div className="cards123__bg-inner" />
                    <img src="../assets/images/signup/img6.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="sponsor__right">
              <div className="w-100 mx-auto sponsor__right__inner">
                <div>
                  <img src="../assets/images/header-logo.svg" alt="logo" />
                </div>
                <h2>The Best Way To Have A Fun!</h2>
                <p>Select Dates to book your slots!</p>
                <div className="date-picker-wrapper mb-3">
                  <div id="my_date_picker" />
                </div>
                <div className="input-text-outer mb-3">
                  <div className="input-bg"><div className="input-bg-inner" /></div>
                  <div className="input-field">
                    <span className="input-field__prefix">Slot Booked For:</span>
                    <input type="text" id="my-date-input" />
                    <span className="input-field__suffix cursor-pointer"><i className="icon icon-edit" /></span>
                    <span className="input-field__suffix cursor-pointer"><i className="icon icon-trash" /></span>
                  </div>
                </div>
                <div className="locationDropdown dropdown mb-3">
                  <div className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="gradient-block-bg">
                      <div className="gradient-block-bg-inner">
                      </div>
                    </div>
                    <img className="icon" src="../assets/images/icons/icon-location2.svg" /> <span className="selected">{active}</span>
                  </div>
                  <ul className="dropdown-menu">
                    <li><a onClick={() => setActive("Club Titos, Goa")} className={`dropdown-item ${active === "Club Titos, Goa" ? "active" : ""}`} role="button">Club Titos, Goa</a></li>
                    <li><a onClick={() => setActive("Dubai")} className={`dropdown-item ${active === "Dubai" ? "active" : ""}`} role="button"> Dubai</a></li>
                  </ul>
                </div>
                <div className="input-button-wrapper mb-3">
                  <button onClick={onClickBuy} data-bs-toggle="modal" data-bs-target="#confirmSlotModal" class=" input-button">
                    <span className="input-button__inner">
                      <span className="input-button__text">Buy a Slot</span>
                      <span className=" input-button__icon-outer">
                        <span className="input-button__icon-inner">
                          <i className="icon icon-double-chevron-dark" />
                        </span>
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div className="modal fade modal--style1 confirmSlotModal" id="confirmSlotModal" tabIndex={-1} aria-labelledby="confirmSlotModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-gradient"><div className="modal-gradient-inner" /></div>
            <div className="modal-header modal-header--noborder">
              <h2 className="modal-title text-center" id="confirmSlotModalLabel">Are you sure you want to book slot for {new Date(currentDate).getDate()} {monthsArray[new Date(currentDate).getMonth()]}, {new Date(currentDate).getFullYear()}!</h2>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body text-center">
              <div className="confirmSlotModal__date">
                <div className="gradient-block-outer left-content">
                  <div className="gradient-block-bg">
                    <div className="gradient-block-bg-inner" />
                  </div>
                  <div className="gradient-block-inner">
                    <div className="text-center slot-number">
                      <p className="fw400">Slot</p>
                    </div>
                    <div className="text-center">
                      <h3 className="fw700">{new Date(currentDate).getDate()}</h3>
                    </div>
                    <div className="text-center">
                      <h5 className="fw400">{monthsArray[new Date(currentDate).getMonth()]}-{new Date(currentDate).getFullYear()}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <p className="slot-desc">Once the slot is booked you can cancel it within 48hrs, After that cancelation fees applied.</p>
              <div className="row gx-lg-5">
                <div className="col-lg-6 mb-3 text-lg-end">
                  <button data-bs-dismiss="modal" aria-label="Close" className="button  text-white border-0 bg-transparent button--getstarted">
                    <span className="button__inner button__inner--cancel">
                      <span className="button__text">No! Don’t want to buy it.</span>
                    </span>
                  </button>
                </div>
                <div className="col-lg-6 text-lg-start">
                  <button onClick={onClickBookSlot} className="button  text-white border-0 bg-transparent button--red button--getstarted">
                    <span className="button__inner">
                      <span className="button__text">Yes! Buy the slot.</span>
                      <span className=" button__iconwrapperouter">
                        <span className="button__iconwrapperinner">
                          <i className="icon icon-double-chevron-dark" />
                        </span>
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
