import axios from "axios";
import { APP_CONFIG } from ".";

const axiosClient = axios.create({
  baseURL: "https://enterxapi.demosimplicity.com/api",
  headers: {
    "Content-Type": "application/json",
  },
})

export default axiosClient;