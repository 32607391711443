import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { axiosClient } from '../../constants';
import AdminTemplate from './AdminTemplate';

export default function AdminMerchant() {
  const [data, setData] = useState(null);
  const headers = { Authorization: "Token " + Cookies.get("ADMIN_TOKEN") }

  useEffect(() => {
    if (data) {
      let script = document.createElement("script");
      script.id = "data-table-script";
      script.innerHTML = `new DataTable('#exampleDataTable');`;
      document.body.appendChild(script);
    }
  }, [data]);

  const getData = () => {
    axiosClient.get("/main/merchant", { headers }).then(resp => {
      setData(resp.data.data);
    }).catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    if (Cookies.get("ADMIN_TOKEN") && Cookies.get("ADMIN_DATA")) { } else {
      window.location.href = "/admin";
    }
    getData();
  }, []);

  return (
    <AdminTemplate active="merchant">
      <div className='container text-white'>
        <h2 className='page-title'>All Merchants</h2>
        <div className="gradient-block-outer">
          <div className="gradient-block-bg">
            <div className="gradient-block-bg-inner"></div>
          </div>
          <div className="gradient-block-inner doc-block2">
            <div className="table-responsive">
              <table id="exampleDataTable" class="table table-striped">
                <thead>
                  <tr>
                    <th>Business Name</th>
                    <th>Contact Name</th>
                    <th>Designation</th>
                    <th>Location</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Nature Of Business</th>
                    <th>Offer</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data?.map(elem => (
                      <tr>
                        <td>{elem.business_name}</td>
                        <td>{elem.contact_name}</td>
                        <td>{elem.designation}</td>
                        <td>{elem.location}</td>
                        <td>{elem.email}</td>
                        <td>{elem.mobile_num}</td>
                        <td>{elem.nature_of_business}</td>
                        <td>{elem.offer}</td>
                        <td><a href={`mailto:${elem.email}`}>
                          <i className='icon icon-email'></i>
                        </a></td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </AdminTemplate>
  )
}