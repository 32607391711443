import React, { useEffect, useState } from 'react'
import Template from '../components/Dashboard/Template'
import { axiosClient } from '../constants';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import RightPassCard from "../components/RightPassCard";

export default function Calendar() {
  const token = Cookies.get("USER_TOKEN");
  const [slotData, setSlotData] = useState(null);
  const [calendarData, setCalendarData] = useState({
    booked_slot: null,
    house_full: null
  });
  let monthsArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const [currEle, setCurrEle] = useState(null);

  useEffect(() => {
    if (calendarData.booked_slot) {
      let script;
      if (document.getElementById("calendar-script")) {
        script = document.getElementById("calendar-script")
      } else {
        script = document.createElement("script");
        script.id = "calendar-script";
      }
      script.innerHTML = `
    $(function() {
      let booked = ${calendarData.booked_slot.length > 0 ? '[' + calendarData.booked_slot.map(ele => "'" + ele + "'") + ']' : '[]'}
      let houseFull = ${calendarData.house_full.length > 0 ? calendarData.house_full : '[]'}
      
      $("#my_date_picker2" ).datepicker({
        firstDay: 1,
        showOtherMonths: true,

        onSelect: function(dateText, instance) {
          document.getElementById("myDateInput").value = dateText;
        },
    
        beforeShowDay: function(date) {
          let formattedDate = $.datepicker.formatDate('yy-mm-dd', date);
          
          if( booked.indexOf(formattedDate) !== -1){
            return [true, 'booked'];
          } else if(houseFull.indexOf(formattedDate) !== -1){
            return [true, 'housefull'];
          }
          return [true, 'available'];
        }
      });
    });
    `;
      if (!document.getElementById("calendar-script")) {
        document.body.appendChild(script);
      }
    }
  }, [calendarData]);

  useEffect(() => {
    getData();
  }, [])

  const getData = async () => {
    try {
      const response = await axiosClient.get("/main/get-order", { headers: { Authorization: "Token " + token } });
      const response1 = await axiosClient.get("/main/get-slot-status?month=" + (new Date().getMonth() + 1), { headers: { Authorization: "Token " + token } });
      setSlotData(response.data?.data?.order_slots);
      setCalendarData(response1.data?.data);
    } catch (error) {
      console.log(error);
      // toast.error("Internal Server Error!!!");
    }
  }

  const onClickCancelSlot = async (e, data) => {
    e.preventDefault();
    setCurrEle(data);
  }

  const cancelSlot1 = async () => {
    try {
      const mData = new FormData();
      mData.append("slot_date", currEle.date);
      const res = await axiosClient.post("/main/cancel-slot", mData, { headers: { Authorization: "Token " + token } });
      toast.success("Slot Cancelled Successfully.");
      window.location.reload();
    } catch (error) {
      console.log(error);
      toast.error("Internal Server Error!!!");
    }
  }

  return (
    <>
      <Template active="calendar" topHeading="Calender">
        <div className="dashboard-widget calendar-widget">
          <div className="dashboard-widget__left">
            <div className='dashboard-widget__bookSlot'>
              <div className="calendar-page-header mb-5">
                <h3 className="calendar-page-title">Book A Slot</h3>
                <p className="calendar-page-desc">You can book maximum 03 slots.</p>
              </div>
              <div className="row mb-lg-5 calendar-slots">
                {
                  slotData?.map((element, index) => {
                    return (
                      <div key={index} className='col-4'>
                        <div className="gradient-block-outer dashboard-gradient-block">
                          <div className="gradient-block-bg">
                            <div className="gradient-block-bg-inner" />
                          </div>
                          <a onClick={(e) => onClickCancelSlot(e, element)} data-bs-toggle="modal" data-bs-target="#confirmSlotModal" className="calendar-slots__edit">
                            <span><i class="icon icon-edit-large"></i></span>
                          </a>
                          <div className="gradient-block-inner">
                            <div className="calendar-slots__num">Slot # {index + 1}</div>
                            <div className="calendar-slots__date">{new Date(element.date).getDate() < 10 ? "0" + new Date(element.date).getDate() : new Date(element.date).getDate()}</div>
                            <div className="calendar-slots__month">{monthsArray[new Date(element.date).getMonth()]}, {new Date(element.date).getFullYear()}</div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
                {
                  slotData && Array.from({ length: 3 - slotData?.length }, (_, i) => i + 1).map((element, index) => {
                    return (
                      <div key={index} className="col-4">
                        <a href="/book-slot" className="calendar-slots__book">
                          <div className="calendar-slots__book-icon">
                            <i className="icon icon-add-big" />
                          </div>
                          <div className="calendar-slots__book-title">Book A New SLot</div>
                        </a>
                      </div>
                    )
                  })
                }

              </div>
            </div>
            <div className="dashboard-widget__calenderInside gradient-block-outer dashboard-gradient-block">
              <div className="gradient-block-bg ">
                <div className="gradient-block-bg-inner" />
              </div>
              <div className="gradient-block-inner">
                <div className="row gx-5">
                  <div className="col-lg-8">
                    <div className="event-calendar date-picker-wrapper">
                      <input type="hidden" id='myDateInput' />
                      <div id="my_date_picker2" />
                    </div>
                  </div>
                  <div className="col-lg-4 text-center">
                    <div className="calendar-slots__info">
                      <div>
                        <i className="calendar-slots__available" />{" "}
                        <span>Available Slots</span>
                      </div>
                      <div>
                        <i className="calendar-slots__housefull" />{" "}
                        <span>House Full (No Slots Available)</span>
                      </div>
                      <div>
                        <i className="calendar-slots__booked" />{" "}
                        <span>Slots You Booked</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-widget__right">
            <div className="single-card gradient-block-outer">
              <div className="gradient-block-bg gradient-block-bg--verticalCard">
                <div className="gradient-block-bg-inner"></div>
              </div>
              <div className="gradient-block-inner ticket-cover">
                <RightPassCard />
                <ul className="list-inline small-passes">
                  <li>
                    <div className="position-relative pass-small">
                      <img
                        src="./assets/images/lock-silver-pass-small.png"
                        alt=""
                      />
                      <a href="#" className="position-absolute">
                        <i className="icon icon-lock" />
                      </a>
                    </div>
                    <div className="w-100 text-white tag fw500 fz12 tag-btn tag--silver">
                      Silver
                    </div>
                  </li>
                  <li>
                    <div className="position-relative pass-small">
                      <img src="./assets/images/lock-gold-pass-small.png" alt="" />
                      <a href="#" className="position-absolute">
                        <i className="icon icon-lock" />
                      </a>
                    </div>
                    <div className="w-100 text-white tag fw500 fz12 tag-btn tag--gold">
                      Gold
                    </div>
                  </li>
                  <li>
                    <div className="position-relative pass-small">
                      <img
                        src="./assets/images/lock-platinum-pass-small.png"
                        alt=""
                      />
                      <a href="#" className="position-absolute">
                        <i className="icon icon-lock" />
                      </a>
                    </div>
                    <div className="w-100 text-white tag fw500 fz12 tag-btn tag--platinum">
                      Platinum
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <h2 className='mt-3'>Credited Pass</h2>
            <hr />
            <div className="single-card gradient-block-outer mt-3">
              <div className="gradient-block-bg gradient-block-bg--verticalCard">
                <div className="gradient-block-bg-inner"></div>
              </div>
              <div className="gradient-block-inner ticket-cover">
                <div className="enterx-card enterx-card--red">
                  <img src="/assets/images/enterx-cards/card-red.svg" alt="..." />
                  <img
                    className="enterx-card__barcode"
                    src="./assets/images/enterx-cards/barcode.svg"
                    alt="..."
                  />
                  <img
                    className="enterx-card__star"
                    src="./assets/images/enterx-cards/star-red.svg"
                    alt="..."
                  />
                  <span className="enterx-card__passno">00001</span>
                  <span className="enterx-card__title1">
                    Limited Offer Price Will Change Shortly
                  </span>
                  <span className="enterx-card__title2">My Music Night Show</span>
                  <span className="enterx-card__title3">
                    Offer: Prepare Yourself For
                  </span>
                  <span className="enterx-card__startxt">
                    <span className="enterx-card__startxt1">E-Star</span>
                    <span className="enterx-card__startxt2">30</span>
                  </span>
                  <span className="enterx-card__slots">00</span>
                  <span className="enterx-card__totalused">00</span>
                  <span className="enterx-card__lastused">N/A</span>
                  <span className="enterx-card__validtill">N/A</span>
                  <span className="enterx-card__startext-red">E-star: 30</span>
                  <span className="enterx-card__startext-silver">E-star: 50</span>
                  <span className="enterx-card__startext-gold">E-star: 100</span>
                  <span className="enterx-card__startext-platinum">
                    E-star: 250
                  </span>
                  <span className="enterx-card__refercode">
                    Referral Code: BIG799BAOH0
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Template>

      {/* Modal For Cancel Slot */}
      <div className="modal fade modal--style1 confirmSlotModal" id="confirmSlotModal" tabIndex={-1} aria-labelledby="confirmSlotModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-gradient"><div className="modal-gradient-inner" /></div>
            <div className="modal-header modal-header--noborder">
              <h2 className="modal-title text-center" id="confirmSlotModalLabel">Are you sure you want to cancel the slot ?</h2>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body text-center">
              <div className="confirmSlotModal__date">
                <div className="gradient-block-outer left-content">
                  <div className="gradient-block-bg">
                    <div className="gradient-block-bg-inner" />
                  </div>
                  <div className="gradient-block-inner">
                    <div className="text-center slot-number">
                      <p className="fw400">Slot</p>
                    </div>
                    <div className="text-center">
                      <h3 className="fw700">{new Date(currEle?.date).getDate()}</h3>
                    </div>
                    <div className="text-center">
                      <h5 className="fw400">{monthsArray[new Date(currEle?.date).getMonth()]}-{new Date(currEle?.date).getFullYear()}</h5>
                    </div>
                  </div>
                </div>
              </div>
              {/* <p className="slot-desc">Once the slot is booked you can cancel it within 48hrs, After that cancelation fees applied.</p> */}
              <div className="row gx-lg-5 mt-4">
                <div className="col-lg-6 text-lg-end">
                  <button data-bs-dismiss="modal" aria-label="Close" className="button  text-white border-0 bg-transparent button--getstarted">
                    <span className="button__inner button__inner--cancel">
                      <span className="button__text">No! Don’t want to cancel it.</span>
                    </span>
                  </button>
                </div>
                <div className="col-lg-6 text-lg-start">
                  <button onClick={() => cancelSlot1()} className="button  text-white border-0 bg-transparent button--red button--getstarted">
                    <span className="button__inner">
                      <span className="button__text">Yes! Cancel the slot.</span>
                      <span className=" button__iconwrapperouter">
                        <span className="button__iconwrapperinner">
                          <i className="icon icon-double-chevron-dark" />
                        </span>
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
