import React, { useEffect, useState } from 'react'
import Template from '../components/Dashboard/Template'
import LevelRed from '../components/Levels/LevelRed';
import LevelGold from '../components/Levels/LevelGold';
import LevelPlatinum from '../components/Levels/LevelPlatinum';
import LevelSilver from '../components/Levels/LevelSilver';
import { useParams } from 'react-router-dom';
import { axiosClient } from '../constants';
import Cookies from 'js-cookie';

export default function Levels() {
  const params = useParams();
  const level = params.level;
  const token = Cookies.get("USER_TOKEN");
  const [data, setData] = useState({});
  const [slotData, setSlotData] = useState(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `
    $(".cards-carousel").owlCarousel({
      center: true,
      loop: true,
      nav: true,
      autoplay: true,
      autoplayTimeout: 3000,
      autoplaySpeed: 1000,
      autoplayHoverPause: true,
      stagePadding: 100,
      responsive: {
        0: {
          items: 1,
          stagePadding: 0,
        },
        600: {
          items: 1.4,
          stagePadding: 25,
        },
        1200: {
          items: 1.5,
          stagePadding: 50,
        },
        
      },
    });
    `;
    document.body.appendChild(script);
  }, []);

  const checkLevel = async () => {
    try {
      const response = await axiosClient.get("/accounts/get-sponsor-tree", { headers: { "Authorization": "Token " + token } });
      const response1 = await axiosClient.get("/main/get-order", { headers: { Authorization: "Token " + token } });
      setSlotData(response1.data?.data?.order_slots);
      const mData = response.data.data;
      setData(mData);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (level) {
      checkLevel();
    }
  }, [level]);

  return (
    <Template active="levels" topHeading="Levels">
      {
        level ? (
          <>
            {level === "red" && <LevelRed data={data} slotData={slotData} />}
            {level === "gold" && <LevelGold data={data} slotData={slotData} />}
            {level === "platinum" && <LevelPlatinum data={data} slotData={slotData} />}
            {level === "silver" && <LevelSilver data={data} slotData={slotData} />}
          </>
        ) : (
          <>
            <div className="slider-section__heading">
              <div className="left">
                <img src="./assets/images/rocket-emoji.svg" alt="" />
                <h2 className="fw600 mb-0 fz18">All Levels</h2>
              </div>
            </div>
            <section className="offers">
              <ul className="list-inline offers-cards">
                <li>
                  <a href="/levels/red" className="cursor-pointer offer-card offer-card--red">
                    <div className="w-100 gradient-block-outer">
                      <div className="gradient-block-bg">
                        <div className="gradient-block-bg-inner" />
                      </div>
                      <img
                        src="./assets/images/offers-bg-01.png"
                        alt=""
                        className="bg-img"
                      />
                      <div className="gradient-block-inner offer-card__inner">
                        {/* <div className="ribbon-large ribbon-large--red">
                          <div className="fw500 fz14">E-Star</div>
                          <div className="fw600 fz20">30</div>
                        </div> */}
                        <div className="offer-content">
                          <h1 className="fw600">
                            Level <br />
                            RED
                          </h1>
                          {/* <p className="fw400 fz12">Total Offers: 200</p> */}
                        </div>
                        <ul className="list-inline">
                          <li>
                            <div className="text-nowrap tag tag-color tag-color--red">
                              Free Entry
                            </div>
                          </li>
                          {/* <li>
                            <div className="text-nowrap tag tag-color tag-color--red">
                              Open Air
                            </div>
                          </li>
                          <li>
                            <div className="text-nowrap tag tag-color tag-color--red">
                              Club Party
                            </div>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/levels/silver" className="cursor-pointer offer-card offer-card--silver">
                    <div className="w-100 gradient-block-outer">
                      <div className="gradient-block-bg">
                        <div className="gradient-block-bg-inner" />
                      </div>
                      <img
                        src="/assets/images/offers-bg-02.png"
                        alt=""
                        className="bg-img"
                      />
                      <div className="gradient-block-inner offer-card__inner">
                        <div className="ribbon-large ribbon-large--silver">
                          <div className="fw500 fz14">Direct Sales</div>
                          <div className="fw600 fz20">25</div>
                        </div>
                        <div className="offer-content">
                          <h1 className="fw600">
                            Level <br />
                            SILVER
                          </h1>
                          {/* <p className="fw400 fz12">Total Offers: 200</p> */}
                        </div>
                        <ul className="list-inline">
                          <li>
                            <div className="text-nowrap tag tag-color tag-color--silver">
                              Red Carpet
                            </div>
                          </li>
                          <li>
                            <div className="text-nowrap tag tag-color tag-color--silver">
                              Complimentary Champagne
                            </div>
                          </li>
                          {/* <li>
                            <div className="text-nowrap tag tag-color tag-color--silver">
                              Club Party
                            </div>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/levels/gold" className="cursor-pointer offer-card offer-card--gold">
                    <div className="w-100 gradient-block-outer">
                      <div className="gradient-block-bg">
                        <div className="gradient-block-bg-inner" />
                      </div>
                      <img
                        src="/assets/images/offers-bg-03.png"
                        alt=""
                        className="bg-img"
                      />
                      <div className="gradient-block-inner offer-card__inner">
                        <div className="ribbon-large ribbon-large--gold">
                          <div className="fw500 fz14">Direct Sales</div>
                          <div className="fw600 fz20">50</div>
                        </div>
                        <div className="offer-content">
                          <h1 className="fw600">
                            Level <br />
                            GOLD
                          </h1>
                          {/* <p className="fw400 fz12">Total Offers: 200</p> */}
                        </div>
                        <ul className="list-inline">
                          <li>
                            <div className="text-nowrap tag tag-color tag-color--gold">
                              Red Carpet
                            </div>
                          </li>
                          <li>
                            <div className="text-nowrap tag tag-color tag-color--gold">
                              Complimentary Champagne
                            </div>
                          </li>
                          <li>
                            <div className="text-nowrap tag tag-color tag-color--gold">
                              Yacht Party
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/levels/platinum" className="cursor-pointer offer-card offer-card--platinum">
                    <div className="w-100 gradient-block-outer">
                      <div className="gradient-block-bg">
                        <div className="gradient-block-bg-inner" />
                      </div>
                      <img
                        src="./assets/images/offers-bg-04.png"
                        alt=""
                        className="bg-img"
                      />
                      <div className="gradient-block-inner offer-card__inner">
                        <div className="ribbon-large ribbon-large--platinum">
                          <div className="fw500 fz14">Direct Sales</div>
                          <div className="fw600 fz20">100</div>
                        </div>
                        <div className="offer-content">
                          <h1 className="fw600">
                            Level <br />
                            PLATINUM
                          </h1>
                          {/* <p className="fw400 fz12">Total Offers: 200</p> */}
                        </div>
                        <ul className="list-inline">
                          <li>
                            <div className="text-nowrap tag tag-color tag-color--platinum">
                              International Trip
                            </div>
                          </li>
                          <li>
                            <div className="text-nowrap tag tag-color tag-color--platinum">
                              Red Carpet
                            </div>
                          </li>
                          <li>
                            <div className="text-nowrap tag tag-color tag-color--platinum">
                              Complimentary Champagne
                            </div>
                          </li>
                          <li>
                            <div className="text-nowrap tag tag-color tag-color--platinum">
                              Yacht Party
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
              <div className="d-none d-lg-block offers-illustration">
                <img src="./assets/images/levels-illustration.svg" alt="" />
              </div>
            </section>

            <div className="slider-section__heading">
              <div className="left">
                <img src="/assets/images/rocket-emoji.svg" alt="" />
                <h2 className="fw600 mb-0 fz18">Club Cards</h2>
              </div>
            </div>

            <section className="passSection clubCardSection">
              <div className="container-fluid px-0">
                <div className="cards-carousel-wrapper">
                  <div className="cards-carousel owl-carousel owl-theme">
                    <div>
                      <a href="/pass/europe" onClick={()=> { Cookies.set("BackHREF", "/levels") }}>
                        <div className="card111">
                          <img className="card111__img" src="../assets/images/enterx-cards/card-gold-europe-s.svg" alt="..." />
                          <div className="card111__number">#000001</div>
                          <div className="card111__starsRed" />
                          <div className="card111__starsSilver">Referral: 25</div>
                          <div className="card111__starsGold">Referral: 50</div>
                          <div className="card111__starsPlatinum">Referral: 100</div>
                        </div>
                      </a>
                    </div>
                    <div>
                      <a href="/pass/dubai" onClick={()=> { Cookies.set("BackHREF", "/levels") }}>
                        <div className="card111">
                          <img className="card111__img" src="../assets/images/enterx-cards/card-gold-dubai-s.svg" alt="..." />
                          <div className="card111__number">#000001</div>
                          <div className="card111__starsRed" />
                          <div className="card111__starsSilver">Referral: 25</div>
                          <div className="card111__starsGold">Referral: 50</div>
                          <div className="card111__starsPlatinum">Referral: 100</div>
                        </div>
                      </a>
                    </div>
                    <div>
                      <a href="/pass/london" onClick={()=> { Cookies.set("BackHREF", "/levels") }}>
                        <div className="card111">
                          <img className="card111__img" src="../assets/images/enterx-cards/card-gold-london-s.svg" alt="..." />
                          <div className="card111__number">#000001</div>
                          <div className="card111__starsRed" />
                          <div className="card111__starsSilver">Referral: 25</div>
                          <div className="card111__starsGold">Referral: 50</div>
                          <div className="card111__starsPlatinum">Referral: 100</div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div class="bottom-nav-space d-lg-none"></div>
          </>
        )
      }
    </Template>
  )
}
